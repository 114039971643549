import { Box, Typography } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";

const RatingBox = () => {
  return (
    <Box sx={{ display: "flex" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          border: "1px solid #9c9c9c",
          padding: "10px",
          borderRadius: "15px 0 0 15px",
          cursor: "pointer",
        }}
        onClick={() => alert("box 1 clicked")}
      >
        <Typography variant="body1" sx={{ marginRight: "3px" }}>
          1
        </Typography>
        <StarIcon sx={{ color: "gold" }} />
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          border: "1px solid #9c9c9c",
          padding: "10px",
          borderLeft: "none",
          cursor: "pointer",
        }}
        onClick={() => alert("box 2 clicked")}
      >
        <Typography variant="body1" sx={{ marginRight: "3px" }}>
          2
        </Typography>
        <StarIcon sx={{ color: "gold" }} />
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          border: "1px solid #9c9c9c",
          padding: "10px",
          borderLeft: "none",
          cursor: "pointer",
        }}
        onClick={() => alert("box 3 clicked")}
      >
        <Typography variant="body1" sx={{ marginRight: "3px" }}>
          3
        </Typography>
        <StarIcon sx={{ color: "gold" }} />
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          border: "1px solid #9c9c9c",
          padding: "10px",
          borderLeft: "none",
          cursor: "pointer",
        }}
        onClick={() => alert("box 4 clicked")}
      >
        <Typography variant="body1" sx={{ marginRight: "3px" }}>
          4
        </Typography>
        <StarIcon sx={{ color: "gold" }} />
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          border: "1px solid #9c9c9c",
          padding: "10px",
          borderLeft: "none",
          borderRadius: "0 15px 15px 0",
          cursor: "pointer",
        }}
        onClick={() => alert("box 5 clicked")}
      >
        <Typography variant="body1" sx={{ marginRight: "3px" }}>
          5
        </Typography>
        <StarIcon sx={{ color: "gold" }} />
      </Box>
    </Box>
  );
};

export default RatingBox;
