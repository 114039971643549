import React, { useContext, useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { useLocation, useNavigate } from "react-router-dom";

import {
  Box,
  Checkbox,
  Chip,
  FormControlLabel,
  FormGroup,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { MRT_ColumnDef, useMaterialReactTable } from "material-react-table";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";

import AccountDrawer from "../../components/commonComponents/accountDrawer/accountDrawer";
import Calendar from "./CalendarComponent";
import TableTemplate from "../../components/reusableComponents/tableTemplate/tableTemplate";
import { getRenterCompletedBooking } from "../../store/thunks/bookingsThunk";
import { logoutUser } from "../../store/thunks/authenticationThunk";
import { logout, resetMessage, setPreviousLocation } from "../../store";

function createData(
  property: string,
  guest: string,
  checkInOut: string,
  bookingStatus: string,
  price: string,
  days: number
) {
  return {
    property,
    guest,
    checkInOut,
    bookingStatus,
    price,
    days,
  };
}

const rows = [
  createData(
    "My Property",
    "Jack Hall",
    "09/13/24 - 09/15/24",
    "Booked",
    "90/Night",
    3
  ),
  createData(
    "My Property",
    "Sam Doe",
    "09/16/24 - 09/20/24",
    "Booked",
    "90/Night",
    4
  ),
];

const CalendarPage = () => {
  const [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { loading, error, loggedIn } = useAppSelector(
    (state: any) => state.authentication
  );

  const { userInfo, previousLocation } = useAppSelector(
    (state: any) => state.authentication
  );

  const { renterCompletedBookingLoading } = useAppSelector(
    (state: any) => state.bookings
  );

  const [renterCompletedBookings, setRenterCompletedBookings] = useState<any[]>(
    []
  );

  useEffect(() => {
    if (!loggedIn) {
      navigate("/login");
    }
  }, [navigate, loggedIn]);

  const handleDrawer = () => {
    setOpen(!open);
  };

  const {
    data: { data = [] } = {}, //your data and api response will probably be different
    isError,
    isRefetching,
    isLoading,
    refetch,
  } = useQuery<any>({
    queryKey: ["table-data"],
    queryFn: async () => {
      const response: any = await dispatch(
        getRenterCompletedBooking({ user_id: userInfo.user_id })
      )
        .then((getRenterCompletedBookingResults: any) => {
          if (getRenterCompletedBookingResults?.payload?.renter_bookings) {
            setRenterCompletedBookings(
              getRenterCompletedBookingResults?.payload?.renter_bookings
            );
          } else if (getRenterCompletedBookingResults?.error) {
            if (
              getRenterCompletedBookingResults?.error?.response?.status === 401
            ) {
              dispatch(logoutUser({ session_id: userInfo?.session_id }));
              dispatch(logout());
              dispatch(resetMessage());
              dispatch(setPreviousLocation(location.pathname));
              navigate("/login");
            }
          }
        })
        .catch((error) => {});
      return response ? response : {};
    },
    placeholderData: keepPreviousData,
  });

  const renterCompletedColumns = useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "property_name",
        enableClickToCopy: true,
        filterVariant: "autocomplete",
        header: "Property Name",
      },
      {
        accessorFn: (row: any) =>
          dayjs(row.booking_create_date).format("MMM DD, YYYY"),
        id: "booking_create_date",
        header: "Booking Date",
        Cell: ({ renderedCellValue, row }) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <span>{renderedCellValue}</span>
          </Box>
        ),
      },
      {
        accessorFn: (row: any) => dayjs(row.check_in).format("MMM DD, YYYY"),
        id: "check_in",
        header: "Check In",
        Cell: ({ renderedCellValue, row }) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <span>{renderedCellValue}</span>
          </Box>
        ),
      },
      {
        accessorFn: (row: any) => dayjs(row.check_out).format("MMM DD, YYYY"),
        id: "check_out",
        header: "Check Out",
        Cell: ({ renderedCellValue, row }) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <span>{renderedCellValue}</span>
          </Box>
        ),
      },

      {
        accessorKey: "total_price",
        enableClickToCopy: true,
        filterVariant: "autocomplete",
        header: "Price",
      },
      {
        accessorKey: "status_label",
        enableClickToCopy: true,
        filterVariant: "autocomplete",
        header: "Status",
      },
    ],
    []
  );

  const renterCompletedTable = useMaterialReactTable({
    columns: renterCompletedColumns,
    data: renterCompletedBookings ? renterCompletedBookings : [], //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    state: {
      isLoading: renterCompletedBookingLoading, //cell skeletons and loading overlay
      // showProgressBars: getUserLoading, //progress bars while refetching
      // isSaving: getUserLoading, //progress bars and save button spinners
    },
    enableColumnFilterModes: true,
    enableColumnOrdering: true,
    enableGrouping: true,
    enableColumnPinning: false,
    enableFacetedValues: true,
    enableRowActions: true,
    enableRowSelection: false,
    initialState: {
      showColumnFilters: true,
      showGlobalFilter: true,
      columnPinning: {
        left: ["mrt-row-expand", "mrt-row-select"],
        right: ["mrt-row-actions"],
      },
    },
    paginationDisplayMode: "pages",
    positionToolbarAlertBanner: "bottom",
    muiSearchTextFieldProps: {
      size: "small",
      variant: "outlined",
    },
    muiPaginationProps: {
      color: "secondary",
      rowsPerPageOptions: [5, 10, 20],
      shape: "rounded",
      variant: "outlined",
    },

    renderRowActionMenuItems: ({ closeMenu, row }) => [],
  });

  return (
    <Box
      sx={{
        padding: "0 20px 0 80px",
        width: "100%",
      }}
    >
      <AccountDrawer handleDrawer={handleDrawer} open={open} />
      <Grid container>
        <Grid item xs={12} sx={{ padding: "20px 0 0 0", marginBottom: "25px" }}>
          <Typography
            variant="h4"
            sx={{
              fontWeight: "bold",
            }}
          >
            Calendar
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Calendar />
        </Grid>
        <Grid item xs={12}>
          <TableTemplate tableData={renterCompletedTable} />
          {/* <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow sx={{ backgroundColor: "#E2F6FC" }}>
                  <TableCell sx={{ fontWeight: "bold" }}>Property</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }} align="right">
                    Guest
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold" }} align="right">
                    Check-In/Out
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold" }} align="right">
                    Gross Booking
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold" }} align="right">
                    Price
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold" }} align="right">
                    Days
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="left" scope="row">
                      {row.property}
                    </TableCell>
                    <TableCell align="right" scope="row">
                      {row.guest}
                    </TableCell>
                    <TableCell align="right">{row.checkInOut}</TableCell>
                    <TableCell align="right">{row.bookingStatus}</TableCell>
                    <TableCell align="right">{row.price}</TableCell>
                    <TableCell align="right">{row.days}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer> */}
        </Grid>
      </Grid>
    </Box>
  );
};

export default CalendarPage;
