import { createSlice } from "@reduxjs/toolkit";
import {
  getRenterBooking,
  getOwnerBooking,
  bookingChekin,
  bookingChekout,
  getPropertyBookings,
  getRenterCompletedBooking,
  getOwnerCompletedBooking,
  getSpecificBooking,
  cancelBooking,
  updateBooking,
  getBookingPayments,
  updateBookingFee,
} from "../../thunks/bookingsThunk";

interface BookingsSlice {
  renterBookingLoading: Boolean;
  ownerBookingLoading: Boolean;
  checkInLoading: Boolean;
  checkOutLoading: Boolean;
  propertyBookingsLoading: Boolean;
  renterCompletedBookingLoading: Boolean;
  ownerCompletedBookingLoading: Boolean;
  specificBookingLoading: Boolean;
  cancelBookingLoading: Boolean;
  updateBookingLoading: Boolean;
  updateBookingFeeLoading: Boolean;

  renterBookings: any[] | null;
  ownerBookings: any[] | null;

  bookingPayments: any[] | null;

  propertyBookings: any[] | null;
  renterCompletedBooking: any[] | null;
  ownerCompletedBooking: any[] | null;

  selectedBooking: any;

  error: any;
  response: any;
}

const initialState: BookingsSlice = {
  renterBookingLoading: false,
  ownerBookingLoading: false,
  checkInLoading: false,
  checkOutLoading: false,

  propertyBookingsLoading: false,
  renterCompletedBookingLoading: false,
  ownerCompletedBookingLoading: false,

  specificBookingLoading: false,
  cancelBookingLoading: false,
  updateBookingLoading: false,
  updateBookingFeeLoading: false,

  error: null,
  response: null,
  renterBookings: [],
  ownerBookings: [],

  propertyBookings: [],
  renterCompletedBooking: [],
  ownerCompletedBooking: [],
  bookingPayments: [],

  selectedBooking: null,
};

const bookingsSlice = createSlice({
  name: "booking",
  initialState,
  reducers: {
    resetBooking: (state) => {
      state.renterBookingLoading = false;
      state.ownerBookingLoading = false;
      state.checkInLoading = false;
      state.checkOutLoading = false;

      state.propertyBookingsLoading = false;
      state.renterCompletedBookingLoading = false;
      state.ownerCompletedBookingLoading = false;

      state.specificBookingLoading = false;
      state.cancelBookingLoading = false;
      state.updateBookingLoading = false;
      state.updateBookingFeeLoading = false;

      state.error = null;
      state.response = null;
      state.renterBookings = [];
      state.ownerBookings = [];

      state.propertyBookings = [];
      state.renterCompletedBooking = [];
      state.ownerCompletedBooking = [];
      state.bookingPayments = [];

      state.selectedBooking = null;
    },
  },
  extraReducers(builder) {
    builder.addCase(getRenterBooking.pending, (state) => {
      state.renterBookingLoading = true;
      state.error = null;
    });
    builder.addCase(getRenterBooking.fulfilled, (state, action) => {
      state.renterBookingLoading = false;
      state.renterBookings = action.payload.renter_bookings;
    });
    builder.addCase(getRenterBooking.rejected, (state, action) => {
      state.renterBookingLoading = false;
      state.error = action.payload;
    });

    builder.addCase(getOwnerBooking.pending, (state) => {
      state.ownerBookingLoading = true;
      state.error = null;
    });
    builder.addCase(getOwnerBooking.fulfilled, (state, action) => {
      state.ownerBookingLoading = false;
      state.ownerBookings = action.payload.owner_bookings;
    });
    builder.addCase(getOwnerBooking.rejected, (state, action) => {
      state.ownerBookingLoading = false;
      state.error = action.payload;
    });

    builder.addCase(bookingChekin.pending, (state) => {
      state.checkInLoading = true;
      state.error = null;
    });
    builder.addCase(bookingChekin.fulfilled, (state, action) => {
      state.checkInLoading = false;
      state.ownerBookings = action.payload.owner_bookings;
    });
    builder.addCase(bookingChekin.rejected, (state, action) => {
      state.checkInLoading = false;
      state.error = action.payload;
    });

    builder.addCase(bookingChekout.pending, (state) => {
      state.checkOutLoading = true;
      state.error = null;
    });
    builder.addCase(bookingChekout.fulfilled, (state, action) => {
      state.checkOutLoading = false;
      state.ownerBookings = action.payload.owner_bookings;
    });
    builder.addCase(bookingChekout.rejected, (state, action) => {
      state.checkOutLoading = false;
      state.error = action.payload;
    });

    builder.addCase(getPropertyBookings.pending, (state) => {
      state.propertyBookingsLoading = true;
      state.error = null;
    });
    builder.addCase(getPropertyBookings.fulfilled, (state, action) => {
      state.propertyBookingsLoading = false;
      state.propertyBookings = action.payload.property_bookings;
    });
    builder.addCase(getPropertyBookings.rejected, (state, action) => {
      state.propertyBookingsLoading = false;
      state.error = action.payload;
    });

    builder.addCase(getRenterCompletedBooking.pending, (state) => {
      state.renterCompletedBookingLoading = true;
      state.error = null;
    });
    builder.addCase(getRenterCompletedBooking.fulfilled, (state, action) => {
      state.renterCompletedBookingLoading = false;
      state.renterCompletedBooking = action.payload;
    });
    builder.addCase(getRenterCompletedBooking.rejected, (state, action) => {
      state.renterCompletedBookingLoading = false;
      state.error = action.payload;
    });

    builder.addCase(getOwnerCompletedBooking.pending, (state) => {
      state.ownerCompletedBookingLoading = true;
      state.error = null;
    });
    builder.addCase(getOwnerCompletedBooking.fulfilled, (state, action) => {
      state.ownerCompletedBookingLoading = false;
      state.ownerCompletedBooking = action.payload;
    });
    builder.addCase(getOwnerCompletedBooking.rejected, (state, action) => {
      state.ownerCompletedBookingLoading = false;
      state.error = action.payload;
    });

    builder.addCase(getSpecificBooking.pending, (state) => {
      state.specificBookingLoading = true;
      state.error = null;
    });
    builder.addCase(getSpecificBooking.fulfilled, (state, action) => {
      state.specificBookingLoading = false;
      state.selectedBooking = action.payload;
    });
    builder.addCase(getSpecificBooking.rejected, (state, action) => {
      state.specificBookingLoading = false;
      state.error = action.payload;
    });

    builder.addCase(cancelBooking.pending, (state) => {
      state.cancelBookingLoading = true;
      state.error = null;
    });
    builder.addCase(cancelBooking.fulfilled, (state, action) => {
      state.cancelBookingLoading = false;
      // state.selectedBooking = action.payload;
    });
    builder.addCase(cancelBooking.rejected, (state, action) => {
      state.cancelBookingLoading = false;
      state.error = action.payload;
    });

    builder.addCase(updateBooking.pending, (state) => {
      state.updateBookingLoading = true;
      state.error = null;
    });
    builder.addCase(updateBooking.fulfilled, (state, action) => {
      state.updateBookingLoading = false;
      // state.selectedBooking = action.payload;
    });
    builder.addCase(updateBooking.rejected, (state, action) => {
      state.updateBookingLoading = false;
      state.error = action.payload;
    });

    builder.addCase(getBookingPayments.pending, (state) => {
      state.cancelBookingLoading = true;
      state.error = null;
    });
    builder.addCase(getBookingPayments.fulfilled, (state, action) => {
      state.cancelBookingLoading = false;
      state.bookingPayments = action.payload.bookings_payments;
    });
    builder.addCase(getBookingPayments.rejected, (state, action) => {
      state.cancelBookingLoading = false;
      state.error = action.payload;
    });

    builder.addCase(updateBookingFee.pending, (state) => {
      state.updateBookingFeeLoading = true;
      state.error = null;
    });
    builder.addCase(updateBookingFee.fulfilled, (state, action) => {
      state.updateBookingFeeLoading = false;
      // state.selectedBooking = action.payload;
    });
    builder.addCase(updateBookingFee.rejected, (state, action) => {
      state.updateBookingFeeLoading = false;
      state.error = action.payload;
    });
  },
});

export const { resetBooking } = bookingsSlice.actions;

export const bookingsReducer = bookingsSlice.reducer;
