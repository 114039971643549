import { Calendar, dayjsLocalizer } from "react-big-calendar";
import dayjs from "dayjs";
import demoEvents from "./demoEvents";

const localizer = dayjsLocalizer(dayjs);

const CalendarComponent = () => (
  <div>
    <Calendar
      events={demoEvents}
      localizer={localizer}
      startAccessor="start"
      endAccessor="end"
      style={{ height: 500 }}
    />
  </div>
);

export default CalendarComponent;
