import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import { useLocation, useNavigate } from "react-router-dom";
import {
  styled,
  useTheme,
  Theme,
  CSSObject,
  makeStyles,
} from "@mui/material/styles";
import { List, Divider, IconButton } from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import HomeIcon from "@mui/icons-material/Home";
import ModeCommentIcon from "@mui/icons-material/ModeComment";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import InsertChartIcon from "@mui/icons-material/InsertChart";
import SettingsIcon from "@mui/icons-material/Settings";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import SingleBedOutlinedIcon from "@mui/icons-material/SingleBedOutlined";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import HistoryIcon from "@mui/icons-material/History";

import DrawerListItem from "./DrawerListItem";

const drawerWidth = 200;

interface AccountDrawerProps {
  handleDrawer: any;
  open: boolean;
}

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const AccountDrawer = (props: AccountDrawerProps) => {
  const { userInfo } = useAppSelector((state: any) => state.authentication);
  const { handleDrawer, open } = props;
  const theme = useTheme();
  // if *true* show certain nav options
  const [isOwner] = useState(true);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Drawer variant="permanent" open={open} sx={{ zIndex: 1 }}>
      <DrawerHeader sx={{ marginTop: "60px" }}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawer}
        >
          {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </DrawerHeader>
      <Divider />
      <List>
        <DrawerListItem
          title="Dashboard"
          icon={<HomeIcon />}
          location="/dashboard"
        />
        <DrawerListItem
          title="Messages"
          icon={<ModeCommentIcon />}
          location="/messages"
        />

        {Number(userInfo?.account_id) > 1 && (
          <DrawerListItem
            title="Calendar"
            icon={<EventAvailableIcon />}
            location="/calendar"
          />
        )}
        {Number(userInfo?.account_id) > 1 && (
          <DrawerListItem
            title="Reports"
            icon={<InsertChartIcon />}
            location="/reports"
          />
        )}
        {Number(userInfo?.account_id) > 1 && (
          <DrawerListItem
            title="Properties"
            icon={<SingleBedOutlinedIcon />}
            location="/properties"
          />
        )}
        <DrawerListItem
          title="History"
          icon={<HistoryIcon />}
          location="/history"
        />
        <DrawerListItem
          title="Settings"
          icon={<SettingsIcon />}
          location={"/settings"}
        />
        {Number(userInfo?.account_id) > 2 && (
          <DrawerListItem
            title="Management"
            icon={<SupervisorAccountIcon />}
            location="/management"
          />
        )}
      </List>
    </Drawer>
  );
};

export default AccountDrawer;
