const now = new Date();

export default [
  /* {
    id: 0,
    title: 'All Day Event very long title',
    allDay: true,
    start: new Date(2015, 3, 0),
    end: new Date(2015, 3, 1),
  }, */
  {
    id: 1,
    title: "Booked by Jack Hall",
    start: new Date(2024, 8, 13),
    end: new Date(2024, 8, 16),
  },

  {
    id: 2,
    title: "Sam Doe",
    start: new Date(2024, 8, 16),
    end: new Date(2024, 8, 20),
  },
  {
    id: 14,
    title: "Today",
    start: new Date(new Date().setHours(new Date().getHours() - 3)),
    end: new Date(new Date().setHours(new Date().getHours() + 3)),
  },
];
