import * as React from "react";
import Box from "@mui/material/Box";
import Rating from "@mui/material/Rating";
import Typography from "@mui/material/Typography";
import exp from "constants";

interface RatingStarProps {
  rating: number;
  color?: string;
}

const RatingStar = (prop: RatingStarProps) => {
  const { rating, color } = prop;
  const [value, setValue] = React.useState<number>(rating);

  return (
    <Box
      sx={{
        "& > legend": { mt: 1 },
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
      }}
    >
      <Rating
        name="half-read-only"
        defaultValue={value}
        precision={0.5}
        readOnly
        style={{ color: color ? color : "#00DCDC" }}
      />
    </Box>
  );
};
export default RatingStar;
