import React from "react";
import {
  Box,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Paper,
  Grid,
  IconButton,
  Button,
  Icon,
} from "@mui/material";

import OwnerIcon from "../../../assets/icons/icon1 1.png";
import RenterIcon from "../../../assets/icons/icon1 1-3.png";
import SecurePaymentIcon from "../../../assets/icons/icon1 1-2.png";
import LessFeeIcon from "../../../assets/icons/icon1 1-1.png";

interface DisplayImageProps {
  image?: any;
  title: string;
  description: string;
  vacationContent: Boolean;
  iconContent: Boolean;
  iconType?: string;
}

const DisplayImage = (prop: DisplayImageProps) => {
  const { image, title, description, vacationContent, iconContent, iconType } =
    prop;
  return (
    <>
      {vacationContent && (
        <Grid
          item
          xs={12}
          sm={6}
          md={3}
          sx={{ marginBottom: "10px", padding: "5px" }}
        >
          <Box sx={{ height: "450px", marginBottom: "10px" }}>
            <img
              src={image}
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
          </Box>
          <Box>
            <Typography
              variant="h5"
              sx={{
                fontWeight: "bold",
                marginBottom: "5px",
              }}
            >
              {title}
            </Typography>
            <Typography
              sx={{
                color: "#9c9c9c",
              }}
            >
              {description}
            </Typography>
          </Box>
        </Grid>
      )}
      {iconContent && (
        <Grid
          item
          xs={12}
          md={3}
          sx={{
            textAlign: "center",
            width: "100%",
          }}
        >
          <Card sx={{ height: "382px", padding: { md: "15px", xs: "25px" } }}>
            <Icon
              sx={{
                width: 100,
                height: 100,
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                margin: "auto",
                marginBottom: "15px",
              }}
              aria-label="search"
            >
              {iconType === "owner" && (
                <img src={OwnerIcon} style={{ width: 100, height: 100 }} />
              )}
              {iconType === "renter" && (
                <img src={RenterIcon} style={{ width: 100, height: 100 }} />
              )}
              {iconType === "payment" && (
                <img
                  src={SecurePaymentIcon}
                  style={{ width: 100, height: 100 }}
                />
              )}
              {iconType === "fee" && (
                <img src={LessFeeIcon} style={{ width: 100, height: 100 }} />
              )}
            </Icon>
            <Typography
              variant="h5"
              sx={{
                fontWeight: "bold",
                paddingBottom: iconContent ? "15px" : "",
              }}
            >
              {title}
            </Typography>
            <Typography
              sx={{
                color: "#9c9c9c",
                fontSize: "16px",
              }}
            >
              {description}
            </Typography>
          </Card>
        </Grid>
      )}
    </>
  );
};

export default DisplayImage;
